import {
  Extrapolation,
  interpolate,
  useAnimatedReaction,
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import {useResponsiveDimensions} from './responsive';

export function useNavChangeScrollAnimate() {
  const {width} = useResponsiveDimensions();
  const designWidth = 375;
  const transformHeight = (width / designWidth) * 40;

  const normalOpacity = useSharedValue(1);
  const scrollTop = useSharedValue(0);
  const scrollHandler = useAnimatedScrollHandler(event => {
    scrollTop.value = event.contentOffset.y;
  });

  const normalNavStyle = useAnimatedStyle(() => {
    return {
      display: normalOpacity.value <= 0 ? 'none' : 'flex',
      opacity: normalOpacity.value,
    };
  }, [normalOpacity]);
  const detailNavStyle = useAnimatedStyle(() => {
    return {
      display: normalOpacity.value >= 1 ? 'none' : 'flex',
      opacity: interpolate(
        normalOpacity.value,
        [0, 1],
        [1, 0],
        Extrapolation.CLAMP,
      ),
    };
  }, [normalOpacity]);

  useAnimatedReaction(
    () => scrollTop.value,
    (result, prev) => {
      const _prev = prev || 0;
      if (result > _prev && result > transformHeight) {
        normalOpacity.value = withTiming(0, {duration: 500});
      }
      if (result < _prev && result < transformHeight) {
        normalOpacity.value = withTiming(1, {duration: 500});
      }
    },
    [scrollTop, normalOpacity, transformHeight],
  );

  return {
    scrollHandler,
    normalNavStyle,
    detailNavStyle,
  };
}
