export const packageId = 3 as number;
export const packageInfo = 'com.sambad.kerala';
export const defaultChannel = 'singam';
export const disabledPhoneCode = true;
export const apiSendPhoneCode = false;
export const defaultPhoneCode = '91';
export const homePage = 'Index';
export const defaultApkPath = 'singam';
export const defaultApkName = 'singam.apk';
export const defaultApkPrefix = 'singam-';
export const gameNavigateVersion = 1;
